import { Component, OnDestroy, OnInit } from '@angular/core';

import { ApiConfiguration } from '../../../api/api-configuration';
import { ApplicationSettingsService } from '../../../services/application-settings/application-settings.service';
import { Subscription } from 'rxjs';
import { StateNamesEnum } from '../../../enum/state-names-enum';
import { SessionStorageService } from '../../../services/session-storage/session-storage.service';
import { StateService } from '../../../services/state/state.service';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'otb-bb-jm-logo',
    standalone: true,
    templateUrl: './bb-jm-logo.component.html',
    imports: [RouterLink, TranslateModule],
    styleUrl: './bb-jm-logo.component.scss'
})
export class BbJmLogoComponent implements OnInit, OnDestroy {
    rootUrl: string = '';
    showUploadedLogo: boolean | undefined;
    subs = new Subscription();

    constructor(
        private applicationSettingsService: ApplicationSettingsService,
        private sessionStorageService: SessionStorageService,
        private apiConfiguration: ApiConfiguration,
        private stateService: StateService
    ) {
        this.rootUrl = this.apiConfiguration.rootUrl;
    }

    ngOnInit() {
        this.subs.add(
            this.applicationSettingsService.showUploadedLogoSubject.subscribe((show: boolean) => {
                this.showUploadedLogo = show ?? false;
            })
        );
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    getRootUrl(): string {
        if (this.applicationSettingsService.serviceOnly) {
            return StateNamesEnum.SERVICE_ONLY;
        }

        return StateNamesEnum.COURT_AND_SERVICE;
    }

    clearStorage(): void {
        this.sessionStorageService.clearWorkflowAndBooking();
        this.stateService.init();
    }
}
