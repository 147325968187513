<label [class]="required ? 'required' : ''" [innerText]="label"></label>
<ng-select
    [name]="name"
    [items]="items"
    [(ngModel)]="selectValue"
    (change)="updateValue()"
    [required]="required"
    dropdownCustomHoverColorDirective
>
</ng-select>
@if (helpText !== null) {
    <small class="form-text text-muted" [innerText]="helpText"></small>
}
