<div class="d-inline-flex py-3">
    @if (showUploadedLogo) {
        <a
            [routerLink]="getRootUrl()"
            (click)="clearStorage()"
            [attr.aria-label]="'dynamische_frontend_texte.header.attribute_text.page_reload' | translate"
        >
            <img class="logo-image" [src]="rootUrl + '/logo/frontend'" alt="Justiz Brandenburg Logo" />
        </a>
    } @else {
        <a
            [routerLink]="getRootUrl()"
            (click)="clearStorage()"
            [attr.aria-label]="'dynamische_frontend_texte.header.attribute_text.page_reload' | translate"
        >
            <img class="logo-image" [src]="rootUrl + '/assets/img/bb-jm/logo.png'" alt="Justiz Brandenburg Logo" />
        </a>
    }
</div>
